import { useEffect, useState } from 'react'

import { Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Unstable_Grid2'

type Props = {
    offset?: boolean
}

export default function BackdropLoader(props: Readonly<Props>) {
    const { offset = false } = props
    const [isSlow, setIsSlow] = useState(false)
    const size = 90

    useEffect(() => {
        const slow = setTimeout(() => {
            setIsSlow(true)
        }, 5000)
        return () => clearTimeout(slow)
    }, [])

    return (
        <Grid sx={{ position: 'absolute', height: 'fill-available', width: 'fill-available' }}>
            <Backdrop
                sx={{
                    position: 'absolute',
                    color: 'primary',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: 'rgba(10, 10, 10, 0.1)',
                }}
                open
            >
                <Grid
                    container
                    spacing={4}
                    xs={12}
                    sx={{ marginTop: offset ? `calc(-25vh - ${size}px)` : '0' }}
                >
                    <Grid xs={12} display="flex" justifyContent="center">
                        <CircularProgress size={size} />
                    </Grid>
                    <Grid xs={12} display="flex" justifyContent="center">
                        {isSlow ? (
                            <Typography variant="subtitle1">
                                Hold on, it is taking longer than usual...
                            </Typography>
                        ) : null}
                    </Grid>
                </Grid>
            </Backdrop>
        </Grid>
    )
}
